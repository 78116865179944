import BaseModel from './BaseModel'

export default class OrderItemModel extends BaseModel {
    get schema() {
        return {
            id: ['id', undefined, value => value],
            productId: ['product_id', undefined, value => value],
            name: ['name', undefined, value => value],
            price: ['price', undefined, value => value],
            quantity: ['quantity', undefined, value => value],
        }
    };

    static attributeLabels = {
        id: 'ID',
        productId: 'ID товара',
        name: 'Название',
        price: 'Цена',
        quantity: 'Количество',
    }
}
