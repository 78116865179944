<template>
    <div v-if="order">
        <AppOrderView
            :date="order.createdAt"
            :products="orderItems"
            :number="order.number"
            :sum="order.sum"
            :status="order.status"
        />

        <v-container fluid>
            <v-row v-if="order.commentDelivery">
                <v-col>
                    <v-textarea
                        name="comment_delivery"
                        rows=2
                        label="Ваши пожелания по времени доставки"
                        v-model="order.commentDelivery"
                        readonly
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row v-if="order.commentQuality">
                <v-col>
                    <v-textarea
                        name="comment_quality"
                        rows=2
                        label="Ваш комментарий по качеству обслуживания"
                        v-model="order.commentQuality"
                        readonly
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid>
            <v-row no-gutters>
                <v-col class="py-1" cols="12">
                    <v-btn
                        block
                        color="primary"
                        @click="repeatOrder()"
                        :disabled="repeatBtnDisabled"
                    >
                        Повторить заказ
                    </v-btn>
                </v-col>
                <!-- <v-col class="py-1" cols="12">
                    <v-btn
                        block
                        color="secondary"
                        text
                    >
                        Редактировать
                    </v-btn>
                </v-col> -->
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {
        VBtn,

        VContainer,
        VRow,
        VCol,

        VTextarea,
    } from 'vuetify/lib'
    import AppOrderView from '@/components/app/order/OrderView'

    import {
        cloneDeep as _cloneDeep,
        map as _map,
    } from 'lodash'

    import { mapGetters, mapMutations, mapActions } from 'vuex'

    import OrderItemModel from '@/models/OrderItemModel'

    export default {
        name: 'Order',

        components: {
            VBtn,

            VContainer,
            VRow,
            VCol,

            VTextarea,

            AppOrderView,
        },

        props: {},

        data() {
            return {
                orderId: undefined,
                orderItems: [],
                repeatBtnDisabled: false,
            }
        },

        computed: {
            ...mapGetters({
                getOrder: 'order/getOrder',
                getProduct: 'product/getProduct',
            }),

            appBarData() {
                return {
                    dark: true,
                    navigationIcon: {
                        type: 'back',
                    },
                    title: 'Заказ',
                }
            },

            order() {
                return _cloneDeep(this.getOrder(this.orderId))
            },
        },

        watch: {
            appBarData: {
                handler: function () {
                    this.setAppBar(this.appBarData)
                },
                immediate: true,
            },
        },

        mounted() {
            this.setOrderId()
            this.fetchOrderById(this.orderId)
                .then(order => {
                    if (order && order.order_items) {
                        this.orderItems = _map(order.order_items, item => new OrderItemModel(item))
                    }
                })
        },

        methods: {
            ...mapMutations({
                setAppBar: 'ui/setAppBar',
                setSelectedProductQuantity: 'product/setSelectedQuantity',
                clearSelectedProductQuantity: 'product/clearSelectedQuantity',
            }),

            ...mapActions({
                fetchOrderById: 'order/fetchById',
                fetchProducts: 'product/fetchProducts',
            }),

            async repeatOrder() {
                let selected = []

                if (!this.orderItems || !this.orderItems.length) {
                    this.showToastError('Не возможно повторить этот заказ')
                }

                this.repeatBtnDisabled = true

                try {
                    await this.fetchProducts()
                } catch (error) {
                    this.repeatBtnDisabled = false
                    this.showToastError(error.errors)
                    
                    return;
                }

                this.repeatBtnDisabled = false

                this.orderItems.forEach(orderItem => {
                    const product = this.getProduct(orderItem.productId)
                    if (product) {
                        selected.push({
                            productId: orderItem.productId,
                            quantity: orderItem.quantity,
                        })
                    }
                });

                if (selected.length) {
                    this.clearSelectedProductQuantity()
                    selected.forEach(item => {
                        this.setSelectedProductQuantity(item)
                    })

                    this.showToast('Был составлен новый заказ', {
                        duration: 2000,
                        type: 'success',
                    })

                    if (selected.length !== this.orderItems.length) {
                        this.showToast('Обратите внимание, что не все позиции вошли в ноый заказ', {
                            duration: 7000,
                            type: 'info',
                        })
                    }
                    
                    this.$router.push({name: 'purchaseOrder'})
                } else {
                    this.showToastError('Не возможно повторить этот заказ')
                }
            },

            setAppBarData() {
                this.setAppBar(this.appBarData)
            },

            setOrderId() {
                this.orderId = this.$route.params.orderId
            },
        },
    }
</script>
